import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const StandardSEO = ({ SEOData, noIndex = false }) => {
  const data = useStaticQuery(graphql`
    {
      sanityCommonContent(language: { eq: "en" }) {
        _id
        backgrounds {
          altText
          asset {
            url
          }
        }
      }
    }
  `);

  const { sanityCommonContent } = data;

  const { backgrounds } = sanityCommonContent;

  const placeholderPreviewImage = backgrounds.find((background) => {
    return background.altText === 'backgroundPlaceholder';
  });

  const { asset } = placeholderPreviewImage;

  let previewImage = '';
  if (SEOData?.previewImage) {
    previewImage = SEOData.previewImage?.asset?.url;
  }

  return (
    <>
      {/* Standard metadata tags */}
      <html lang="en-GB" />
      <title>{SEOData.title}</title>
      <meta name="description" content={SEOData.description} />
      <meta name="keywords" content={SEOData.keywords} />

      {/* Open Graph tags */}
      <meta
        property="og:image"
        content={SEOData.blogPreviewImage || previewImage || asset.url}
      />
      <meta property="og:type" content={SEOData.type} />
      <meta property="og:title" content={SEOData.title} />
      <meta property="og:description" content={SEOData.description} />

      {/* Twitter tags */}
      <meta name="twitter:creator" content={SEOData.name} />
      <meta
        name="twitter:image"
        content={SEOData.blogPreviewImage || previewImage || asset.url}
      />
      <meta name="twitter:card" content={SEOData.type} />
      <meta name="twitter:title" content={SEOData.title} />
      <meta name="twitter:description" content={SEOData.description} />

      {/* No index */}
      {noIndex && <meta name="robots" content="noindex" />}
    </>
  );
};

export default StandardSEO;
