import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import FanGoButton from '../components/common/button/FanGoButton';
import StandardSEO from '../components/common/seo/StandardSEO';
import styles from '../components/common/styling/styles';
import { MainText } from '../components/common/texts';
import Animation from '../components/PageComps/CommonComps/animation/Animation';
import {
  checkmark,
  checkmark__check,
  checkmark__circle,
} from '../components/PageComps/ContactSuccessPage/contactSuccess.module.css';
import { getLink } from '../utils/getLink';

const seo = {
  title: 'Contact Success | FanGo',
  description:
    'The FanGo team specialise in events management software including ticket booking, room hire, pre-orders and pre-payment. They are designing and developing bespoke systems for clients across the UK.',
  name: 'FanGo',
  type: 'page',
};

const ContactSuccess = () => {
  useEffect(() => {
    if (window && window.dataLayer) {
      window.dataLayer.push({ event: 'success_trigger' });
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'white',
        pt: 20,
      }}
    >
      <svg className={checkmark} viewBox="0 0 52 52">
        <circle
          className={checkmark__circle}
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className={checkmark__check}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>

      <Animation yValue={50}>
        <MainText
          textVariant="h1"
          fontColor={styles.darkBlue}
          value="Thank you"
        />

        <MainText
          textVariant="h4"
          fontColor={styles.grey}
          marginY={styles.allTwo}
          value="Your message has been sent!"
        />
        <Box
          sx={{
            textAlign: 'center',
            mt: 5,
          }}
        >
          <FanGoButton
            buttonName="Go Back Home"
            fontSize={25}
            link={getLink({ key: 'home' })}
            formatLink={false}
          />
        </Box>
      </Animation>
    </Box>
  );
};

export default ContactSuccess;

export const Head = () => <StandardSEO SEOData={seo} />;
